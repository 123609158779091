export const ApiEndPoints = {
  BULK_UPLOAD: {
    upload: 'bulk-upload/BulkUpload/upload',
    download: 'bulk-upload/BulkUpload/download-uploaded-file',
    downloadErrorFile: 'bulk-upload/BulkUpload/download-error-templates',
    delete: 'bulk-upload/BulkUpload/delete-bulk-upload',
  },

  DATA_ACCESS_GROUP: {
    dataAccessGroupOverview: 'client-management/data-access-groups/',
    dataAccessGroupAdd: 'client-management/data-access-groups/add',
    dataAccessGroupEdit: 'client-management/data-access-groups/edit',
    dataAccessGroupDelete: 'client-management/data-access-groups/delete',
    dataAccessGroupsFilters: 'client-management/data-access-groups/filters',
    validateName: 'client-management/data-access-groups/validations/name',
  },

  QUEUE_REQUESTS: {
    actualStatus: 'queue-request/QueueRequests/actual',
    historicalStatus: 'queue-request/QueueRequests/history',
    filters: 'queue-request/QueueRequests/filters',
  },

  LEGAl_ENTITY: {
    legalEntityOverview: 'legalEntity/overview',
    legalEntityCreate: 'legalEntity/create',
  },

  ORGANIZATIONAL_UNIT: {
    organizationalUnitCreate: 'organizational-unit/OrganizationalUnit/addOrganizationalUnit',
    organizationalUnitOverview: 'organizational-unit/OrganizationalUnit/getOrganizationalUnitOverview',
    organizationalUnitDetailsByID: 'organizational-unit/OrganizationalUnit/getOrganizationalUnitByID',
    organizationalUnitEdit: 'organizational-unit/OrganizationalUnit/editOrganizationalUnit',
    organizationalUnitDelete: 'organizational-unit/OrganizationalUnit/deleteOrganizationalUnit',
    organizationalUnitList: 'organizational-unit/OrganizationalUnit/getOrganizationalUnitList',
    organizationFilters: 'organizational-unit/OrganizationalUnit/getOrganizationalUnitFilters',
    organizationalUnitFilters: 'organizational-unit/OrganizationalUnit/getOrganizationalUnitFilters',
    organizationUnitDownloadTemplate: 'organizational-unit/OrganizationalUnit/get-download-templates',
    organizationUnitPotentialParentsForExistingUnit: 'organizational-unit/OrganizationalUnit/getPotentialParentsForExistingUnit',
    organizationUnitCodeValidation: 'organizational-unit/OrganizationalUnit/validation/orgunitcode',
    organizationUnitCustomAttributes: 'organizational-unit/OrganizationalUnit/getCustomAttributesSchema',
    getOrganizationalUnits: 'organizational-unit/OrganizationalUnit/getOrganizationalUnits',
    getOrganizationalUnitsFilters: 'organizational-unit/OrganizationalUnit/getOrganizationalUnitsFilters',
    organizationalUnitSelectList: 'organizational-unit/OrganizationalUnit/list',
    dropdownOptions: 'organizational-unit/OrganizationalUnit/dropdown-options',
  },

  GEOGRAPHICAL_UNIT: {
    geographicalUnitOverview: 'geographical-unit/GeographicalUnit/getGeographicalUnitOverview',
    geographicalUnitFilters: 'geographical-unit/GeographicalUnit/getGeographicalUnitFilters',
    geographicalUnitDetailsByID: 'geographical-unit/GeographicalUnit/getGeographicalUnitByID',
    geographicalUnitType: 'geographical-unit/GeographicalUnitType/getGeographicalType',
    geographicalUnitList: 'geographical-unit/GeographicalUnit/getGeographicalUnitList',
    geographicalUnitCreate: 'geographical-unit/GeographicalUnit/addGeographicalUnit',
    geographicalUnitEdit: 'geographical-unit/GeographicalUnit/editGeographicalUnit',
    geographicalUnitDelete: 'geographical-unit/GeographicalUnit/deleteGeographicalUnit',
    geographicalUnitDownloadTemplate: 'geographical-unit/GeographicalUnit/get-download-templates',
    geographicalUnitPotentialParentsForExistingUnit: 'geographical-unit/GeographicalUnit/getPotentialParentsForExistingUnit',
    geographicalUnitCodeValidation: 'geographical-unit/GeographicalUnit/validation/code',
    geographicalUnitNameValidation: 'geographical-unit/GeographicalUnit/validation/name',
    geographicalUnitCustomAttributes: 'geographical-unit/GeographicalUnit/getCustomAttributesSchema',
    geographicalUnitHierarchyList: 'geographical-unit/GeographicalUnit/getGeographicalHierarchyList',
    geographicalUnitSelectList: 'geographical-unit/GeographicalUnit/list',
    getgeographicalUnits: 'geographical-unit/GeographicalUnit/getGeographicalUnits',
    getgeographicalUnitsFilters: 'geographical-unit/GeographicalUnit/getGeographicalUnitsFilters',
    dropdownOptions: 'geographical-unit/GeographicalUnit/dropdown-options',
  },

  ORG_UNIT_TYPE: {
    orgUnitTypeList: 'organizational-unit/OrganizationalUnitType/getOrganizationalUnitTypeDetails',
    orgUnitTypeByLevel: 'organizational-unit/OrganizationalUnitType/getOrganizationalUnitTypeByLevel',
  },

  ORGANIZATIONAL_HIERARCHY_LIST: {
    organizationalHierarchyList: 'organizational-unit/OrganizationalHierarchyList/getOrganizationalHierarchyList',
    createEmptyHierarchy: 'geographical-unit/GeographicalUnitHierarchyList/add',
  },

  COUNTRY_DETAILS: {
    countryList: 'Country/getCountryList',
  },

  REGION_DETAILS: {
    regionList: 'Region/getRegionDetails',
  },

  STATE_PROVINCE_DETAILS: {
    stateProvinceList: 'StateProvince/getStateProvinceList',
  },

  CLIENT_MANAGEMENT: {
    clientOverview: 'tenant/tenants/',
    clientCreate: 'tenant/tenants/add',
    clientById: 'tenant/tenants',
    clientUpdate: 'tenant/tenants/edit',
    clientDelete: 'tenant/tenants/delete',
    clientFilters: 'tenant/tenants/filters',
    clientNameValidation: 'tenant/tenants/validations/client-name',
    clientDbIdentifierValidation: 'tenant/tenants/validations/dbIdentifier',
    checkIntegrationProcessIsRunning: 'tenant/integration/status'
  },

  CATEGORY_TEMPLATE: {
    categoryTemplateOverview: 'tenant/category-templates/',
    getCategoryTemplatesModules: 'tenant/category-templates/domains',
    categoryTemplateCreate: 'tenant/category-templates/add',
    categoryTemplateEdit: 'tenant/category-templates/edit',
    categoryTemplateDelete: 'tenant/category-templates/delete',
  },

  CUSTOM_ATTRIBUTES: {
    customCategoryTemplates: 'client-management/custom-attributes-templates/',
    customAttributes: 'client-management/custom-attributes-templates/custom-attributes',
    customAttributesDetails: 'client-management/custom-attributes',
    createCustomAttributesDetails: 'client-management/custom-attributes/add',
    updateCustomAttributesDetails: 'client-management/custom-attributes/update',
    deleteCustomAttributesDetails: 'client-management/custom-attributes/delete',
    controlTypes: 'client-management/custom-attributes/control-types',
    dictionaryDomains: 'client-management/custom-attributes/dictionary-domains',
    rules: 'client-management/custom-attributes/rules',
    externalService: 'client-management/custom-attributes/external-dictionary',
    validateUniqueName: 'client-management/custom-attributes/validations/name',
    customAttributesReportTypes: 'client-management/custom-attributes/report-types',
    getFreeTextFilters: 'client-management/custom-attributes/filters/types/free-text',
  },

  SERVICE_OFFERING: {
    serviceOfferingById: 'service-offering/ServiceOffering/getServiceOfferingByID',
    serviceOfferingCreate: 'service-offering/ServiceOffering/addServiceOffering',
    serviceOfferingCustomAttributes: 'service-offering/ServiceOffering/getCustomAttributesSchema',
    serviceOfferingDelete: 'service-offering/ServiceOffering/deleteServiceOffering',
    serviceOfferingDownloadTemplate: 'service-offering/ServiceOffering/download-templates',
    serviceOfferingList: 'service-offering/ServiceOffering/getServiceOfferingList',
    serviceOfferingListByServiceTypeId: 'service-offering/ServiceOffering/getServiceOfferingListByServiceTypeId',
    serviceOfferingNameValidation: 'service-offering/ServiceOffering/validation/serviceofferingname',
    serviceOfferingOverview: 'service-offering/ServiceOffering/getServiceOfferingOverview',
    serviceOfferings: 'service-offering/ServiceOffering/service-offerings',
    serviceOfferingsEditStatus: 'service-offering/ServiceOffering/statuses/edit',
    serviceOfferingsFilters: 'service-offering/ServiceOffering/filters',
    serviceOfferingUpdate: 'service-offering/ServiceOffering/editServiceOffering',
    dropdownOptions: 'service-offering/ServiceOffering/dropdown-options',
  },

  SOURCE_DATA_ITEM: {
    sourceDataItemById: 'source-data-item/SourceDataItemVariant/getSourceDataItemByID?sourceDataItemID=${sdiID}',
    sourceDataItemCreate: 'source-data-item/SourceDataItemVariant/addSourceDataItemVariant',
    sourceDataItemCustomAttributes: 'source-data-item/SourceDataItemVariant/getCustomAttributesSchema',
    sourceDataItemDelete: 'source-data-item/SourceDataItemVariant/deleteSourceDataItemVariant',
    sourceDataItemDownloadTemplate: 'source-data-item/SourceDataItemVariant/download-templates',
    sourceDataItemEditStatus: 'source-data-item/SourceDataItemVariant/statuses/edit',
    sourceDataItemFilters: 'source-data-item/SourceDataItemVariant/filters',
    sourceDataItemList: 'source-data-item/SourceDataItemVariant/getSourceDataItemList',
    sourceDataItemNameValidation: 'source-data-item/SourceDataItemVariant/validation/sourcedataitemname',
    sourceDataItemOverview: 'source-data-item/SourceDataItemVariant/getSourceDataItemOverview',
    sourceDataItemParameter: 'source-data-item/SourceDataItemVariant/getSourceDataItemParameter',
    sourceDataItemParameterDelete: 'source-data-item/SourceDataItemVariant/deleteSourceDataItemParameter',
    sourceDataItemParameterOverview: 'source-data-item/SourceDataItemVariant/getSourceDataItemParameterOverview',
    sourceDataItemParametersDownloadTemplate: 'source-data-item/SourceDataItemVariant/parameters/download-templates',
    sourceDataItemParameterUpdate: 'source-data-item/SourceDataItemVariant/editSourceDataItemParameter',
    sourceDataItemUpdate: 'source-data-item/SourceDataItemVariant/editSourceDataItemVariant',
  },

  SERVICE_SUBSCRIPTION: {
    getServiceSubscriptions: 'service-subscription/ServiceSubscription/getServiceSubscriptions',
    getServiceSubscriptionsFilters: 'service-subscription/ServiceSubscription/getServiceSubscriptionsFilters',
    serviceSubscription: 'service-subscription/ServiceSubscription/',
    serviceSubscriptionCreate: 'service-subscription/ServiceSubscription/add',
    serviceSubscriptionCustomAttributes: 'service-subscription/ServiceSubscription/getCustomAttributesSchema',
    serviceSubscriptionDelete: 'service-subscription/ServiceSubscription/deleteServiceSubscription',
    serviceSubscriptionDownloadTemplate: 'service-subscription/ServiceSubscription/download-templates',
    serviceSubscriptionUpdate: 'service-subscription/ServiceSubscription/edit',
    serviceSubscriptionValidation: 'service-subscription/ServiceSubscription/validation/duplicate',
    serviceSubscriptionGetIrs: 'service-subscription/ServiceSubscription/information-request-list',
    serviceSubscriptionPeriodRange: 'service-subscription/ServiceSubscription/subscription-period-range',
    serviceSubscriptionEditStatus: 'service-subscription/ServiceSubscription/statuses/edit',
  },

  SERVICE_INSTANCE: {
    // serviceInstanceOverview: 'ServiceInstance/getServiceInstanceOverview',
    serviceInstanceById: 'service-instance/ServiceInstance/getServiceInstanceByID',
    serviceInstanceEditStatus: 'service-instance/ServiceSubscriptionItem/statuses/edit',
    serviceInstanceUpdate: 'service-instance/ServiceInstance/editServiceInstance',
    // serviceInstanceDelete: 'ServiceInstance/deleteServiceInstance',
    // serviceInstanceFilters: 'ServiceInstance/getServiceInstanceFilters',
    // serviceInstanceDownloadTemplate: 'ServiceInstance/download-templates',
    serviceInstanceCustomAttributesSchema: 'service-instance/ServiceInstance/getCustomAttributesSchema',
    serviceInstanceParameterOverview: 'service-instance/ServiceInstance/{id}/parameters',
    serviceInstanceParameter: 'service-instance/ServiceInstance/{id}/parameters/{parameterId}',
    serviceInstanceParameterUpdate: 'service-instance/ServiceInstance/{id}/parameters/edit/{parameterId}',
    serviceInstanceParameterDelete: 'service-instance/ServiceInstance/{id}/parameters/delete/{parameterId}',
    serviceInstanceParametersDownloadTemplate: 'service-instance/ServiceInstance/parameters/download-templates',
    serviceInstanceTypes: 'service-instance/ServiceSubscriptionItem/instance-types',
  },

  CONNECTOR_TYPE: {
    connectorTypeList: 'source-data-item/ConnectorType/getConnectorTypeList',
  },

  DATA_SOURCE_SINK: {
    dataSourceList: 'organizational-unit/DataSource/getDataSourceList',
    dataSourceListByOrgUnitId: 'organizational-unit/DataSource/getDataSourceListByOrgUnitId',
    systemTypesList: 'organizational-unit/DataSource/getSystemTypesList',
  },

  PERIODICITY: {
    getPeriodicities: 'service-subscription/Periodicity/getPeriodicityList',
  },

  CALENDAR_TYPE: {
    getCalendarTypes: 'service-subscription/CalendarType/getCalendarTypeList',
  },

  SERVICE_TYPE: {
    getServiceTypes: 'service-offering/ServiceType/getServiceTypeList',
    getSubServiceTypes: 'service-offering/ServiceType/getServiceTypeList',
    getServiceTypeHierarchy: 'service-offering/ServiceType/getServiceTypeHierarchy',
    getParentsForServiceType: 'service-offering/ServiceType/getParentsForServiceType',
    serviceTypeNameValidation: 'service-offering/ServiceType/validation/serviceTypeName',
    validateCyclicalServiceType: 'service-offering/ServiceType/validation/validateCyclicalServiceType',
    serviceTypeCreate: 'service-offering/ServiceType/addServiceType',
    serviceTypeListByServiceTypeId: 'service-offering/ServiceType/getServiceTypeByID',
    serviceTypeOverview: 'service-offering/ServiceType/overView',
    serviceTypeUpdate: 'service-offering/ServiceType/editServiceType',
    serviceTypeDownloadTemplate: 'service-offering/ServiceType/download-templates',
    serviceTypeFilters: 'service-offering/ServiceType/filters',
    serviceTypeDelete: 'service-offering/ServiceType/delete',
    serviceTypeCustomAttributes: 'service-offering/ServiceType/getCustomAttributesSchema',
    serviceTypeList: 'service-offering/ServiceType/list',
  },

  LOGIN: {
    clientListForLogin: 'tenant/Tenant/getClientDetails',
    extendSession: 'tenant/session/extend',
    logoutSession: 'tenant/session/logout',
    validateSessions: 'tenant/session/validateSessions',
  },
  USER_SESSION: {
    healthCheck: 'identity/user-session/healthcheck',
  },
  TOKEN: {
    authorizeUser: 'identity/authentication/authenticate',
    allTokens: 'identity/authentication/refresh-token',
    authorizeUserRoles: 'identity/authentication/authenticateuserintenant',
  },

  COMPLIANCE_CALENDAR: {
    complianceCalendarByID: 'compliance-calendar/ComplianceCalendar/getComplianceCalendarByID',
    complianceCalendarFilters: 'compliance-calendar/ComplianceCalendar/getComplianceCalendarFilters',
    complianceCalendarOverview: 'compliance-calendar/ComplianceCalendar/getComplianceCalendarOverview',
    getComplianceCalendarFilters: 'compliance-calendar/ComplianceCalendar/filters',
    getComplianceCalendars: 'compliance-calendar/ComplianceCalendar/overview',
    getServiceSubscriptionItemInstances: 'service-subscription/ServiceSubscriptionItem/service-subscription-item-instances',
    getServiceSubscriptionItemInstanceFilters: 'service-subscription/ServiceSubscriptionItem/service-subscription-item-filters',
    getServiceSubscriptionItemInstanceDetails: 'service-subscription/ServiceSubscriptionItem/service-subscription-item-instance-details',
  },

  RBAC_USERS: {
    usersOverview: 'rbac/users',
    filters: 'rbac/users/filters',
    delete: 'rbac/users/delete',
    edit: 'rbac/users/edit',
    add: 'rbac/users/add',
    userById: 'rbac/users',
    validateEmail: 'rbac/users/validations/user-email',
    usersGroups: 'rbac/users/users-groups',
  },

  TENANTS: {
    dictionary: 'tenant/tenants/dictionary',
    integrateExternalProviderData: 'tenant/tenants/integrate-external-provider-data',
  },

  RBAC_USER_GROUPS: {
    userGroupsOverview: 'rbac/user-groups',
    filters: 'rbac/user-groups/filters',
    delete: 'rbac/user-groups/delete',
    details: 'rbac/user-groups',
    add: 'rbac/user-groups/add',
    edit: 'rbac/user-groups/edit',
    validateName: 'rbac/user-groups/validations/user-group-name',
  },

  RBAC_ROLES: {
    rolesOverview: 'rbac/roles',
    addRole: 'rbac/roles/add',
    rolesFilters: 'rbac/roles/filters',
    applications: 'rbac/applications',
    privileges: 'rbac/privileges',
    applicationModules: 'rbac/application-modules',
    validateName: 'rbac/roles/validations/role-name',
    roleDetails: 'rbac/roles',
    delete: 'rbac/roles/delete',
    editRole: 'rbac/roles/edit',
  },

  ENGAGEMENTS: {
    add: 'engagement/Engagements/add',
    details: 'engagement/Engagements/details',
    edit: 'engagement/Engagements/edit',
    editStatus: 'engagement/Engagements/statuses/edit',
    engagementCustomAttributes: 'engagement/Engagements/getCustomAttributesSchema',
    engagementDownloadTemplate: 'engagement/Engagements/download-templates',
    filters: 'engagement/Engagements/filters',
    list: 'engagement/Engagements/list',
    overview: 'engagement/Engagements/overview',
    validateUniqueName: 'engagement/Engagements/validations/name',
    dropdownOptions: 'engagement/Engagements/dropdown-options',
  },

  SCOPE_PROFILES: {
    add: 'engagement/ScopeProfiles/add',
    customAttributeSchemas: 'engagement/ScopeProfiles/getCustomAttributesSchema',
    delete: 'engagement/ScopeProfiles/$1/delete',
    details: 'engagement/ScopeProfiles/$1/details',
    detailsTab: 'engagement/ScopeProfiles/details',
    edit: 'engagement/ScopeProfiles/edit',
    engagements: 'engagement/ScopeProfiles/engagements',
    filters: 'engagement/ScopeProfiles/filters',
    jurisdictions: 'engagement/ScopeProfiles/jurisdictions/overview',
    overview: 'engagement/ScopeProfiles/overview',
    statuses: 'engagement/ScopeProfiles/statuses',
    taxRegistrationCountries: 'engagement/ScopeProfiles/taxRegistrationCountries',
    validate: 'engagement/ScopeProfiles/validate',
  },

  FILE_SERVICE: {
    queueExport: 'file/v1/fileservice/queue-export',
    downloadExportedFile: 'file/v1/fileservice/download-exported-file',
  },

  FORM: {
    fieldCustomizations: 'form/v1/forms/attributes/field-customizations',
    customization: 'form/v1/forms/attributes/customization',
  },

  ENTITY_STATUS: {
    entityStatusesByTableName: 'client-management/entity-statuses/referenced-table-names/',
  },

  WORK_PRODUCT: {
    dropdownOptions: 'client-management/work-products/dropdown-options',
    defaultOption: 'client-management/work-products/resource-types/$1/geographical-units/$2/service-types/$3/service-offerings/$4/work-product-default',
  },
};

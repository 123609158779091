import { Injectable } from '@angular/core';
import { ModelAdapter } from './model.adapter';

export interface ICustomization {
  displayName: string;
  domain: string;
  isHidden: boolean;
  key: string;
  label: string;
}

export class Customization implements ICustomization {
  public displayName: string;
  public domain: string;
  public isHidden: boolean;
  public key: string;
  public label: string;

  constructor(obj?: ICustomization) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomizationAdapter implements ModelAdapter<ICustomization> {
  public adapt(source: any): Customization {
    const target: Customization = new Customization(source);

    target.key = `${source.domain}/${source.label}`;

    return target;
  }
}

import { ErrorModel } from '../models/error-model';

export class ErrorsContainer {
  readonly separator = ' ' as const;

  public static errGeneric: ErrorModel = {
    message: 'Something went wrong. Please try again later.',
    resutCode: 'ERR_GENERIC',
    description: [''],
  };
  public static errNoInternetConnection: ErrorModel = {
    message: 'No internet connection',
    resutCode: 'ERR_NO_INTERNET',
    description: ['Please check you network cable :-)'],
  };
}

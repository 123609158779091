import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent {
  @Input() field: any;

  getErrorLabel(): string {
    const validationError = this.getValidationError();

    switch (validationError) {
      case 'required': {
        return 'This is a mandatory field.';
      }

      case 'duplicate': {
        return 'A record with the provided details already exists.';
      }

      case 'maxlength': {
        return `Length cannot exceed ${this.field.errors?.maxlength?.requiredLength} characters.`;
      }

      case 'pattern':
      case 'min':
      case 'max':
      case 'matDatepickerParse': {
        return 'Invalid value';
      }
      case 'matStartDateInvalid': {
        return 'Invalid value';
      }
      default : {
        return validationError;
      }
    }
  }

  getValidationError() {
    const errorsWithCustomMessage = this.field?.errors;

    const errors = Object.keys(this.field?.errors);
    const priorityErrors = ['pattern', 'min', 'max', 'matDatepickerParse', 'maxlength', 'matStartDateInvalid'];

    const errorsInPriorityOrder = priorityErrors.map(priorityError => errors.find(item => priorityError == item)).filter(mappedError => mappedError);
    
    if(errors[0] == 'customErrorMessage')
      errors[0] = errorsWithCustomMessage.customErrorMessage;

    return errorsInPriorityOrder.length > 0 ? errorsInPriorityOrder[0] : errors[0];
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoggedInGuard } from './core/guards/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./core/modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'app',
    loadChildren: () => import('./core/modules/shell/shell.module').then(m => m.ShellModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginStatusService } from '../../shared/services/login-status.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard  {
  constructor(private router: Router, private msal: MsalGuard, private loginStatusService: LoginStatusService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.loginStatusService.userLoggedInStatus$.getValue().loginStatus) {
      this.router.navigate(['app/organization/organizational-unit']);
      return false;
    }
    return true;
  }
}

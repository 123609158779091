export enum Modules {
  CalendarManagement = 'CC',
  CategoryTemplate = 'CT',
  ClientManagement = 'CL',
  DataAccessGroupsManagement = 'DA',
  EngagementManagement = 'EN',
  FileManagement = 'FL',
  FormManagement = 'FM',
  GTPManagement = 'GT',
  GeographicalUnitManagement = 'GU',
  OrganizationUnitManagement = 'OU',
  QueueRequest = 'QR',
  RoleManagement = 'RL',
  ScopeProfileManagement = 'SP',
  ServiceInstanceManagement = 'SI',
  ServiceOfferingManagement = 'SO',
  ServiceTypeManagement = 'ST',
  SourceDataItemManagement = 'SD',
  SubscriptionManagement = 'SS',
  UserGroupManagement = 'UG',
  UserManagement = 'US',
}
